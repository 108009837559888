 <template>
    <div class="slide" v-if="$store.state.event">
        <div class="slidecon">
            <div class="eventop">{{title}}</div>
            <div class="eventcen">
                <div class="eventleft">
                    <div>
                        <span>赛事信息</span>
                    </div>
                    <div class="htmlContainer" v-html="htmlContent"></div>
                </div>
                <!-- 右边 -->
                <div class="eventright">
                    <div class="eventapply lightBack">
                        <div style="width:0.5rem;height:0.42rem;">
                            <img :src="list.image" alt="" style="width:100%;height:100%;">
                        </div>
                        <div>
                            <p>{{list.registration}}</p>
                            <p>
								<countTo :startVal='0' :endVal='list.registrationnum' :duration='3000'></countTo>
							</p>
                        </div>
                    </div>
                    <div class="eventchart">
                        <div class="fangcharts">
                            <!-- 第一个 -->
                            <div>
                                <div class="blueFont">● 运动年龄分布</div>
                                <div id="sportAge1"></div>
                            </div>
                            <div>
                                <div class="width50 ">
                                    <div class="blueFont font18">● 男女比例</div>
                                    <div class="imgGroup">
                                        <img class="manPic" src="../../../assets/images/man1.png" >
                                        <img class="womanPic" src="../../../assets/images/woman1.png" >
                                    </div>
                                    <div class="legendText">
                                        <span>男</span>
                                        <span>女</span>
                                    </div>
                                    <div class="proportion">
                                        <div class="man">{{ratio.man}}</div>
                                        <div class="woman">{{ratio.woman}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eventbot">
                        <div v-for="(item, index) in img" :key="index" @click="imgshow">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="remove">
                <img src="../../../assets/images/remove.png" alt="" @click="removeimg">
            </div>
        </div>
    </div>
</template>

<script>
import bus from "../../../assets/js/bus";
import {getAjax, timestamp} from "/src/assets/js/websocket";

	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
    data () {
        return {
            title:"",
            htmlContent:"",

            ratio:{
                man:'',
                woman:'',
            },

            list:{
                registration:"报名人数",
                registrationnum: 0,
                image:require('../../../assets/images/apply1.png'),
            },

            img:[
                {image:require('../../../assets/images/exercise1.png')},
                {image:require('../../../assets/images/exercise2.png')},
            ],

           age:[
                // {value: 1048, name: '青少年'},
                // {value: 735, name: '青年'},
                // {value: 580, name: '中年'},
                // {value: 484, name: '老年'},
           ],
           detailTimer:"",
           match_id:""
        }
    },
    methods: {
        imghandle(e){
            this.slideimg = e.target.src
        },
        removeimg(){
            this.$store.state.event = false
        },
        imgshow(){
            this.$store.state.event = false
            this.$store.state.slideshow = true
        },
        // 绘制运动年龄图
        drawSport(number){
            // let myChart = this.$echarts.init(document.getElementById("sportAge1"));
            let myChart = this.$echarts.getInstanceByDom(document.getElementById("sportAge1"))
            if (myChart == null) { // 如果不存在，就进行初始化
                myChart = this.$echarts.init(document.getElementById("sportAge1"));
            }
            let option = {
                legend: {
                    bottom:0,
                    left: 'center',
                    itemWidth:15,
                    itemHeight:15,
                    itemGap:5,
                    textStyle: { //图例文字的样式
                        color: '#7EA7E2',
                        fontSize: 14
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['28%', '40%'],
                            // 在容器中的位置
                        center:['50%','40%'],
                        avoidLabelOverlap: false,
                        labelLine: {
                            show: true,
                            //设置延长线的长度
                            normal: {
                                length: 15,//设置延长线的长度
                                length2: 10,//设置第二段延长线的长度
                                lineStyle: {
                                    color: "#ffffff"  // 改变标示线的颜色
                                }
                            },
                        }, 
                            // 字体颜色及大小
                        label:{
                            color:'#D2F8FF',
                            textStyle:{fontSize:12},
                        },
                        color:['#1D77D2','#E35D68','#1BBA79','#F45E23'],
                        data: number
                    }
                ]
            };
            
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            // 图表自动缩放
            window.addEventListener("resize",function(){
                myChart.resize();
				// $("#sportAge").css({width:"2rem",height:"2.3rem"})
            });
        },
        getData(id){
            let that = this;
            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getMatchInfo?id="+id,
                method:"GET",
                data:data,
                success:function(result){
                    // console.log(result)
                    let res=result.data
                    that.title = res.title;
                    that.htmlContent = res.desc;
                    that.list.registrationnum = res.num;
                    if(res.images.length <= 2){
                        that.img = res.images;
                    }else{
                        that.img = [res.images[0],res.images[1]]
                    }
                    
                    if(res.images.length <= 5){
                        bus.$emit('match_photo', res.images);
                    }else{
                        bus.$emit('match_photo', [res.images[0],res.images[1],res.images[2],res.images[3],res.images[4],]);
                    }
                    that.ratio.man = res.men_women_proportion[0].proportion + "%";
                    that.ratio.woman = res.men_women_proportion[1].proportion + "%";
                    that.age = [];
                    res.old.forEach((item,index)=>{
                        that.age.push({
                            value:item.value,
                            name:item.name
                        })
                    })
                    that.drawSport(that.age);
                },
            })
        }
    },
    mounted () {
        let that = this;
        setInterval(() => {
            that.getData(that.match_id);
        }, 300000);
    },
    created () {
        let that = this;
        bus.$on('match_detail_id',id=>{
            // console.log(id) 
            that.match_id = id;
            that.getData(id);
        })  
    },
    beforeDestroy(){
        bus.$off('match_detail_id');
        clearInterval(this.detailTimer)
    },
    activated(){
        let that = this;
		// that.drawSport(this.age);
    },
}
</script>

<style scoped>
.slide{
    width:19.20rem;
    height:10.80rem;
    background:#000000e8;
    border:0.01rem solid #000000B2;
    position: relative;
    z-index: 1000;
}
.slidecon{
    width:10.30rem;
    height:7.33rem;
    padding:0rem 0.36rem 0.33rem 0.36rem;
    position: relative;
    margin: auto;
    margin-top: 1.7rem;
    border:0.01rem solid #104E83;
    box-sizing: border-box;
}
.eventop{
    line-height: 0.8rem;
    color:#FFFFFF;
    font-size: 0.2rem;
    text-align: left;
    border-bottom:0.01rem solid #104E83;
    margin-bottom: 0.2rem;
}
.eventcen{
    display: flex;
    justify-content: space-between;
}
.eventleft{
    width:4.09rem;
    height:5.72rem;
    text-align: left;
    overflow: hidden;
}
.eventleft>div{
    color:#7EA7E2;
    font-size: 0.16rem;
    margin-bottom:0.2rem;
}
.eventleft>div>p{
    line-height: 0.23rem;
}
.eventright{
    width:4.8rem;
}
.blueFont{
    font-size: 0.18rem;
    color:#20D7FE;
}
.eventapply{
    width:2.30rem;
    height:1.0rem;
    color:#A8DAEB;
    font-size: 0.14rem;
    display: flex;
    align-items: center;
}
.eventapply>div:nth-child(1){
    margin:0rem 0.27rem 0rem 0.4rem;
}
.remove{
    width:0.34rem;
    height:0.34rem;
    border-radius: 50%;
    position: absolute;
    top:-0.14rem;
    right:-0.14rem;
}
.remove>img{
    width:100%;
    height:100%;
}


.fangcharts{
    display: flex;
    justify-content: space-between;
    font-size: 0.18rem;
    margin-top:0.40rem;
    text-align: left;
}
.fangcharts>div{
    width:50%;
}
#sportAge1{
    width:2.0rem;
    height:2.30rem;
    margin-left:0.2rem;
}

.font18{ font-size: 0.18rem; }
.imgGroup{
    width: 1.85rem;
    display: flex;
    justify-content: space-between;
    padding-left: 0.1rem;
    margin-top: 20%;
}
.manPic{
    width: 0.39rem;
    height: 0.46rem;
}
.womanPic{
    width: 0.43rem;
    height: 0.46rem;
}
.legendText{
    display: flex;
    justify-content: space-between;
    color: #7B8CB7;
    font-size: 0.12rem;
    width: 1.85rem;
    margin: 0.15rem 0rem 0.05rem 0.1rem;
}
.proportion{
    display: flex;
    padding-left: 0.1rem;
    margin-top:0.2rem;
}
.man, .woman{
    color: white;
    text-align: center;
    font-size: 0.14rem
}
.man{
    width: 0.92rem;
    background-color: #0E83C8;
}
.woman{
    width: 0.92rem;
    background-color: #E88A2B;
}

.eventbot{
    width:4.29rem;
    height:1.13rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin:auto;
    margin-top:0.4rem;
}
.eventbot>div{
    width:2.01rem;
    height:1.13rem;
}
.eventbot>div>img{
    width:100%;
    height:100%;
}
.htmlContainer{
    height: 5.5rem;
    overflow-y: auto;
}
.htmlContainer >>> img{
    width: 90%;
}
 /*滚动条样式*/
.htmlContainer::-webkit-scrollbar {
    width: 0.05rem;    
    height: 0rem;
}


.htmlContainer::-webkit-scrollbar-thumb {
    border-radius: 0.1rem;
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    background: #7EA7E2;
}
.htmlContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    border-radius: 0.1rem;
}
</style>