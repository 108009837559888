<template>
<!-- 赛事-弹框-照片展示 -->
    <div class="slide" v-if="$store.state.slideshow">
        <div class="slidecon darkBack">
            <div class="slidetop">
                <img :src="slideimg" alt="">
            </div>
            <div class="slideswiper">
                <div class="slidebot">
                    <div v-for="(item, i) in img" :key="i">
                        <img :src="item" alt="" @click="imghandle(i)">
                    </div>
                </div>
            </div>
            <div class="remove">
                <img src="../../../assets/images/remove.png" alt="" @click="removeimg">
            </div>
        </div>
    </div>
</template>

<script>
import bus from "../../../assets/js/bus";

export default {
    data () {
        return {
            slideimg: "",
            img:[
                // {image:require('../../../assets/images/slide1.png')},
                // {image:require('../../../assets/images/slide2.png')},
                // {image:require('../../../assets/images/slide3.png')},
                // {image:require('../../../assets/images/slide4.png')},
                // {image:require('../../../assets/images/slide5.png')},
            ]
            
        }
    },
    methods: {
        imghandle(num){
            // console.log(num)
            this.slideimg = this.img[num]
        },
        removeimg(){
            this.$store.state.slideshow = false
        }
    },
    created () {
        bus.$on('match_photo',res=>{
            this.img = res;
            this.slideimg = res[0];
        })
    },
    beforeDestroy(){
        bus.$off('match_photo')
    }

}
</script>

<style scoped>
.slide{
    width:19.20rem;
    height:10.80rem;
    background:#000000B2;
    position: relative;
    text-align: center;
    z-index: 1000;
}
.slidecon{
    width:10.30rem;
    height:7.33rem;
    padding:0.29rem 0.36rem 0.33rem 0.36rem;
    display: inline-block;
    position: relative;
    margin-top: 1.7rem;
    box-sizing: border-box;
}
.slidetop{
    width:9.57rem;
    height:5.38rem;
    margin-bottom: 0.19rem;
}
.slidetop>img{
    width:100%;
    height:100%;
}
.slidebot{
    width:100%;
    height:1.13rem;
    overflow: hidden;
    /* display: flex;
    justify-content: space-between; */
}
.slidebot>div{
    display: inline-block;
    width:1.75rem;
    height:1.13rem;
    margin-left:0.2rem;
}
.slidebot>div:nth-child(1){
    margin-left:0rem;
}
.slidebot>div>img{
    width:100%;
    height:100%;
}
.remove{
    
    width:0.34rem;
    height:0.34rem;
    border-radius: 50%;
    position: absolute;
    top:-0.14rem;
    right:-0.14rem;
}
.remove>img{
    width:100%;
    height:100%;
}
</style>