<template>
<!-- 越城区体彩销售排行 右边 -->
<div style="position:relative">
    <div class="ticai darkBack">
        <div class="ticaitop">
            <div style="color:#fff;font-size:0.2rem">赛事列表</div>
            <div class="ticainput">
                <input type="text" placeholder="请输入" v-model="match_name" @keyup.enter="search_match"/>
                <div @click="search_match">搜索</div>
                <img src="../../../assets/images/sousuo.png" alt="">
            </div>
        </div>

        <table id="thead">
            <thead>
                <td>赛事活动名称</td>
                <td>赛事参赛人数</td>
            </thead>
        </table>
        <vueSeamlessScroll :data="matchList" class="seamlessWarp" :class-option="classOption">
            <table id="table">
                <tr v-for="(item,index) in matchList" :key="index"> 
                    <td @click="skipevent(item.id)">{{item.name}}</td>
                    <td>{{item.money}}</td>
                </tr>
            </table>
        </vueSeamlessScroll>

    </div>
    <keep-alive>
        <eventpopout v-if="$store.state.event" class="eventpopout1"></eventpopout>
        <slideshow v-if="$store.state.slideshow" class="eventpopout1"></slideshow>
    </keep-alive>
    
</div>
    
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'     
import eventpopout from '../eventstwo/eventpopout.vue'      // 越城国际荧光酷跑节——弹框
import slideshow from '../eventsthree/slideshow.vue'      // 越城国际荧光酷跑节——弹框
import bus from "../../../assets/js/bus";
 import {getAjax, timestamp} from "/src/assets/js/websocket";
export default {
    components: {
        eventpopout,
        slideshow,
        vueSeamlessScroll,
    },
    data () {
        return {    
            matchList:[    
                // {name: '喜临门绍兴马拉松', money: '21300'},
                // {name: '中国御茶村抹茶马拉松', money: '242'},
                // {name: '2021嗨翻绍兴环城河皮划艇列赛', money: '125'},
                // {name: '2021富盛南宋文化旅游', money: '982'},
                // {name: '黄酒杯环城马拉松', money: '213'},
                // {name:"绍兴市青少年街舞锦标赛",money:'98'},
                // {name:"绍兴市青少年跆拳道锦标赛",money:'823'},
                // {name:"浙江省第十二届中小学校园足球联赛",money:'430'},
                // {name:"绍兴市青少年（儿童）游泳锦标赛",money:'876'},
                // {name:"绍兴市青少年乒乓球锦标赛",money:"240"},
                // {name:"2021年国际象棋男子世界冠军分赛",money:"46"},
                // {name:"2021年绍兴市青少年柔道锦标赛",money:"116"},
                // {name:"2021年全国田径冠军暨奥运选拔赛",money:"600"},
                // {name:"2021年绍兴市青少年网球锦标赛",money:"56"},
                // {name:"2021年全国田径冠军赛暨奥运选赛",money:"765"},
                // {name:"奥体“市长杯”幼儿游泳比赛",money:"366"},
                // {name:"2021杨汛桥街道“杭绍篮球争霸赛”",money:"543"},
            ],
            list:[],
            match_name:""
        }
    },
    mounted () {
        let that = this;
        // console.log(that)
        that.getMatchList()
    },
    methods: {
        // 打开详情
        skipevent(id){
            this.$store.state.event = true;
            // console.log(id)
            setTimeout(() => {
                bus.$emit('match_detail_id',id);
            }, 200);
        },
        // 获取赛事列表
        getMatchList(){
            let that = this;
            bus.$on('match_list',function(res){
                // console.log(res)
                that.matchList = [];
                res.forEach((item,index)=>{
                    that.matchList.push({
                        name:item.title,
                        money:item.num,
                        id:item.id
                    })
                })
                // console.log(that.matchList)
            })      
        },
        // 搜索赛事
        search_match(){
            let that = this;
            let list;
            // console.log(that.match_name)
            var data = {
                    token: window.getToken(),
                    app_id: window.appId,
                    time: timestamp(),
                }
            getAjax({
                url: "/map/screen/getMatchAll?title="+that.match_name,
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
                    list = res.data.match_list.data;
                    // console.log(list)
                    that.matchList = [];
                    list.forEach((item,index)=>{
                        that.matchList.push({
                            name:item.title,
                            money:item.num,
                            id:item.id
                        })
                    })
                },
            })
        }
    },
    computed: {
        classOption () {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 16, // 开始无缝滚动的数据量 this.dataList.length
                // limitMoveNum: this.matchList.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    
    destroyed () {
        bus.$off('match_list')  
    },
}
</script>
 
<style scoped>
.eventpopout1{
    position:absolute;
    top:-20%;
    left:-10%;
}
.ticai{
    width: 5.06rem;
    height:7.20rem;
    padding: 0.2rem;
    color:#ffffff;
    font-size: 0.20rem;
    position: relative;
}
.ticaitop{
    display: flex;
    justify-content: space-between;
}
.ticaitop>div:nth-child(1){
    float: left;
}
/* .ticaitop>div{
    width:50%;
} */
.ticaitop>div:nth-child(2){
    float: right;
}
.ticainput{
    position: relative;
    width:2.05rem;
    height:0.25rem;
}
.ticainput>input{
    width:2.05rem;
    height:0.25rem;
    text-indent: 0.20rem;
    background:none;  
	outline:none;  
	border:0.01rem solid #5d5d5d;
    border-radius: 0.125rem;
    color:#FFFFFF;
    box-sizing: border-box;
}
.ticainput>div{
    width:0.57rem;
    height:0.24rem;
    line-height: 0.24rem;
    border-radius: 0.125rem;
    background:#5589FF;
    position: absolute;
    top:0.01rem;
    right:0;
    font-size: 0.13rem;
    text-align: center;
    color:#08142C;
    font-weight: 500;
    cursor: pointer;
}
.ticainput>img{
    width:0.14rem;
    height:0.14rem;
    position: absolute;
    top:0.06rem;
    left:0.06rem;
}


.seamlessWarp{
    width: 4.6rem;
    height: 5.86rem;
    overflow: hidden;
}
#table{
    color: #7EA7E2;
    font-size: 0.14rem;
    text-align: center;
    width: 4.0rem;
    max-height: 5.86rem;
}
#thead{
    margin: 0.3rem 0rem 0.1rem 0rem;
    width: 4.2rem;
    color: #20D7FE;
    font-size: 0.14rem;
    text-align: right;
}
#thead td:last-child{
    margin-left:0.6rem;
    /* text-align: center; */
}
</style>